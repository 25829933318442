
const LoginPage = () => {

	return(
		<>
			<h1>
				Hello There
			</h1>
		</>
	)
}

export default LoginPage
